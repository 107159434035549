import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import zendesk from "helpers/zendesk";
import useUserSubscriptionQuery from "hooks/Subscriptions/useUserSubscription";
import { PageBody } from "app/components/RouteComponents";
import Alert from "app/components/Alert";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import { H2, P1 } from "app/components/Typography";
import SpanLink from "app/components/SpanLink";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import Button from "app/components/Button";
import { SubscriptionProvider, SubscriptionStatus } from "services/graphql";
import { Routes } from "constants/routes";
import { getUserSubscription } from "modules/user/actions";
import LinkButton from "app/components/Button/LinkButton";
import { useUserSubscription } from "modules/selectors";
import { useSubscriptionReactivate, useSubscriptionResume } from "./hooks";
import SubscriptionInfo from "./SubscriptionInfo";
import ProfilePhoto from "../Settings/ProfilePhoto";
import { AccountBodyWrapper } from "../AccountBodyWrapper";

const ButtonWrapper = styled.div`
  margin: 10px 0;
`;

const Subscription = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const providerData = useSelector(({ auth }: any) => auth.providerData);
  const { isSubscriptionActive } = useUserSubscription();

  const [error, setError] = useState(null);

  const [data = {}, userLoading, userError] = useUserSubscriptionQuery({
    fetchPolicy: "network-only",
  });
  const {
    subscriptionReactivate,
    subscriptionReactivateResults,
  } = useSubscriptionReactivate({
    onCompleted: () => {
      dispatch(getUserSubscription());
    },
  });
  const {
    loading: subscriptionReactivateLoading,
    error: subscriptionReactivateError,
  } = subscriptionReactivateResults;

  const { subscriptionResume } = useSubscriptionResume();

  const isLoading = userLoading || subscriptionReactivateLoading;
  const subscriptionError = userError || subscriptionReactivateError;

  if (isLoading) {
    return <LoaderCentered />;
  }

  if (!data?.me?.primarySubscription) {
    return <Redirect to="/checkout" />;
  }

  const {
    me: { primarySubscription },
  } = data;
  const {
    id: subscriptionId,
    isCancelable,
    isPauseScheduled,
    isPastDue,
    isReactivatableV2,
    status: subscriptionStatus,
    cancelationTime,
    plan,
    provider,
  } = primarySubscription;

  const isSpecialPlan = plan.isSpecial;
  const isOnLegacyPlan = plan.isLegacy;

  const handleSubscriptionReactivate = () => {
    subscriptionReactivate({
      variables: {
        subscriptionId,
      },
    });
  };

  const goToCancelationFlow = async () => {
    history.push("/account/cancel");
  };

  const handleSubscriptionResumption = () => {
    subscriptionResume({
      variables: {
        subscriptionId,
      },
    });
  };

  if (!cancelationTime && isPastDue) {
    return <Redirect to="/billing-past-due" />;
  }

  return (
    <Div>
      <Helmet title="Subscription | Online Dance Classes and Tutorials" />
      <PageBody>
        <ProfilePhoto providerData={providerData} />
        <Flex flexDirection="column" textAlign="center" my={4}>
          <H2>Subscription Settings</H2>
        </Flex>
        {subscriptionError && (
          <Alert variant="danger">
            There was a problem loading your subscription. Please try again or{" "}
            <SpanLink onClick={() => zendesk("webWidget", "open")}>
              contact us for help!
            </SpanLink>
          </Alert>
        )}
        {provider.name === SubscriptionProvider.Chargebee && (
          <Flex flexDirection="column">
            <Flex justifyContent="center">
              <SubscriptionInfo />
            </Flex>
            <ButtonWrapper>
              <Flex justifyContent="center" mt={3} width="100%">
                {isReactivatableV2 && (
                  <Button
                    variant="warning"
                    width={{ _: "100%", md: "60%" }}
                    color="black"
                    disabled={
                      isSpecialPlan ||
                      (isOnLegacyPlan &&
                        subscriptionStatus !== SubscriptionStatus.NonRenewing)
                    }
                    onClick={handleSubscriptionReactivate}
                  >
                    Remove Scheduled Cancelation
                  </Button>
                )}
              </Flex>

              <Flex justifyContent="center" mt={3} width="100%">
                {primarySubscription && !primarySubscription?.isActive && (
                  <Button
                    width={{ _: "100%", md: "60%" }}
                    onClick={() => history.push(Routes.checkout)}
                  >
                    Reactivate My Subscription
                  </Button>
                )}
              </Flex>
              {(subscriptionStatus === SubscriptionStatus.Canceled ||
                subscriptionStatus === SubscriptionStatus.NonRenewing) && (
                <Flex justifyContent="center" mt={3} width="100%">
                  <Button
                    variant="success"
                    width={{ _: "100%", md: "60%" }}
                    onClick={() => history.push("/account/cancel-success")}
                  >
                    Reactivate with 50% off
                  </Button>
                </Flex>
              )}
              {subscriptionStatus === SubscriptionStatus.Paused && (
                <Flex justifyContent="center" mt={3} width="100%">
                  <Button
                    variant="warning"
                    width={{ _: "100%", md: "60%" }}
                    color="black"
                    onClick={handleSubscriptionResumption}
                  >
                    Resume Subscription
                  </Button>
                </Flex>
              )}
              {isPauseScheduled && (
                <Flex justifyContent="center" mt={3} width="100%">
                  <Button
                    variant="warning"
                    width={{ _: "100%", md: "60%" }}
                    color="black"
                    onClick={handleSubscriptionResumption}
                  >
                    Remove Scheduled Pause
                  </Button>
                </Flex>
              )}
            </ButtonWrapper>
            <Flex
              alignSelf="center"
              justifyContent="space-between"
              width={{ _: "100%", md: "60%" }}
            >
              {isCancelable && !isSpecialPlan && (
                <SpanLink color="black" underline onClick={goToCancelationFlow}>
                  Cancel Subscription
                </SpanLink>
              )}
            </Flex>
            {isSpecialPlan && !isOnLegacyPlan && (
              <Flex alignSelf="center" width={{ _: "100%", md: "60%" }}>
                <P1>
                  Your account is on an exclusive plan. To modify your
                  subscription, please contact support{" "}
                  <SpanLink
                    onClick={() => zendesk("webWidget", "open")}
                    underline
                  >
                    here
                  </SpanLink>
                </P1>
              </Flex>
            )}
            {isOnLegacyPlan &&
              subscriptionStatus === SubscriptionStatus.Canceled && (
                <Flex alignSelf="center" width={{ _: "100%", md: "60%" }}>
                  <P1>
                    Your account is on a legacy plan. To modify your
                    subscription, please contact support{" "}
                    <SpanLink
                      onClick={() => zendesk("webWidget", "open")}
                      underline
                    >
                      here
                    </SpanLink>
                  </P1>
                </Flex>
              )}
            {error && (
              <Alert variant="danger" closeAlert={() => setError(null)}>
                {error.message}
              </Alert>
            )}
          </Flex>
        )}
        {provider.name === SubscriptionProvider.Revenuecat && (
          <>
            <Alert variant="danger">
              Your subscription was created through the STEEZY Studio{" "}
              <a
                href="https://apps.apple.com/app/steezy-learn-how-to-dance/id1296001664"
                target="blank"
              >
                iOS
              </a>{" "}
              or{" "}
              <a
                href="https://play.google.com/store/apps/details?id=co.steezy.app"
                target="blank"
              >
                Android
              </a>{" "}
              mobile app. To manage your subscription, please open the app on
              your mobile device or{" "}
              <SpanLink onClick={() => zendesk("webWidget", "open")}>
                contact us for help!
              </SpanLink>
            </Alert>
            {/*
             * Need to check isSubscriptionaActive because a user can have multiple subs
             * Sometimes a user's primary subscription might be canceled, but they have another sub that is active
             */}
            {subscriptionStatus === SubscriptionStatus.Canceled &&
              !isSubscriptionActive && (
                <Div>
                  <P1>
                    If you'd like to subscribe through the web, click here:
                  </P1>
                  <LinkButton to="/checkout">Subscribe</LinkButton>
                </Div>
              )}
          </>
        )}
        {provider.name === SubscriptionProvider.Steezy && (
          <Alert variant="danger">
            Our subscription system is under maintenance. To manage your
            subscription, come back later or{" "}
            <SpanLink onClick={() => zendesk("webWidget", "open")}>
              contact us for help!
            </SpanLink>
          </Alert>
        )}
      </PageBody>
    </Div>
  );
};

export default () => (
  <AccountBodyWrapper>
    <Subscription />
  </AccountBodyWrapper>
);
